<template>
  <div class="c-pagination">
    <div class="c-pagination__text">
      {{ params.total }}件中 {{ pageFrom }} - {{ pageTo }} 件を表示
    </div>
    <div class="c-pagination__ctrl">
      <paginate
        :value="page"
        :page-count="paginationCount"
        :page-range="5"
        :margin-pages="2"
        :click-handler="clickCallback"
        :prev-text="''"
        :next-text="''"
        :prev-class="'prev'"
        :next-class="'next'"
        :break-view-class="'dotted'"
        :active-class="'current'"
        :container-class="'c-pagination'"
        :page-class="'c-pagination__btn'"
      >
      </paginate>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";

export default {
  components: {
    Paginate,
  },
  props: {
    params: {
      type: Object,
    },
    page: {
      type: Number,
    },
  },
  computed: {
    pageFrom() {
      if (this.params.page === 1) {
        return this.params.page;
      } else {
        return this.params.page * this.params.pageSize - this.params.pageSize + 1;
      }
    },
    pageTo() {
      if (this.params.page * this.params.pageSize > this.params.total) {
        return this.params.total;
      } else {
        return this.params.page * this.params.pageSize;
      }
    },
    paginationCount() {
      const count = Math.ceil(this.params.total / this.params.pageSize);
      if (isNaN(count)) {
        return 0;
      } else {
        return count;
      }
    },
  },
  methods: {
    clickCallback(pageNum) {
      this.$emit("update:page", pageNum);
      this.$emit("callback");
    },
  },
};
</script>
