<template>
  <transition name="fade">
    <div v-if="toastMsg" class="c-toast">
      {{ toastMsg }}
    </div>
  </transition>

</template>

<script>
import {COOKIE_INFO} from "@/const";

export default {
  props: {
    toastMsg: {
      type: String,
      default: "",
    },
  },
  watch: {
    toastMsg() {
      if (this.toastMsg) {
        setTimeout(() => {
          this.$emit("update:toastMsg", "");
        }, 5000);
      }
    }
  },
  mounted() {
    // 登録完了などしてクッキーにメッセージがあるか判定
    // アニメーションさせるためマウント後に判定
    if (this.$cookies.get(COOKIE_INFO.MESSAGE)) {
      this.$emit("update:toastMsg", this.$cookies.get(COOKIE_INFO.MESSAGE));
      this.$cookies.remove(COOKIE_INFO.MESSAGE);
    }
  }
};
</script>
