import axios from "axios";
import {API_URL} from "@/const";

export default {
  data() {
    return {
      jwnetItemsOpt: [],
    };
  },

  methods: {
    getJwnetItemTypeApi() {
      this.jwnetItems = [];
      return axios
        .get(API_URL.DOMAIN.JWNET_ITEM_TYPE)
        .then((res) => {
          this.jwnetItemsOpt = res.data.jwnetItems;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
