import {API_URL, DOMAIN} from "@/const";
import axios from "axios";

export default {
  methods: {
    // URL生成
    getSearchApiUrl() {
      const params = new URLSearchParams();
      params.append("page", this.paginationParams.page);
      params.append("pageSize", this.paginationParams.pageSize);
      params.append("sort", `${this.sortParams.column}:${this.sortParams.type}`);
      if (this.searchParam.tempInputSearch) {
        this.searchParam.tempInputSearch.replace(/\s/, " ").split(" ")
          .forEach((keysearch) => { params.append("keysearch", keysearch); });
      }
      if (this.searchParam.businessTypeIdList) {
        this.searchParam.businessTypeIdList.forEach((businessTypeId) => { params.append("businessTypeSearch", businessTypeId); });
      }
      if (this.searchParam.isCBAContractList) {
        // [] or [true] or [false] or [true, false] の配列になる可能性がある
        // [] と [true, false] は検索条件に指定しないことと同義なので、[true] or [false] の場合のみ検索条件に追加
        if (this.searchParam.isCBAContractList.length === 1) {
          params.append("isCBAContract", this.searchParam.isCBAContractList[0]);
        }
      }
      if (this.searchParam.accountStartDateFrom) params.append("accountStartDateFrom", this.searchParam.accountStartDateFrom);
      if (this.searchParam.accountStartDateTo) params.append("accountStartDateTo", this.searchParam.accountStartDateTo);
      if (this.searchParam.contractStatusTypeId) params.append("contractStatusTypeId", String(DOMAIN.GENERATE_CONTRACT_SEARCH_STATUS.MAIL_NOTSETTING));
      if (this.searchParam.jwnetItemTypeSearch) {
        this.searchParam.jwnetItemTypeSearch.forEach((jwnetItemType) => { params.append("jwnetItemTypeSearch", jwnetItemType.id); });
      }

      return API_URL.OPERATE.SEARCH + '?' + params.toString();
    },

    // 処分事業者検索API 検索結果
    getOperateSearchApi(isPaging) {
      if (!isPaging) {
        this.paginationParams.page = 1; // 1ページ目に初期化
      }
      axios
        .get(this.getSearchApiUrl())
        .then((res) => {
          this.operateList = res.data.operateList;
          this.paginationParams = res.data.searchParams.pagination;
          this.sortParams = res.data.searchParams.sorts[0];
          this.$store.commit("searchParamOperate/set", this.searchParam);
        })
        .catch((err) => {
          this.operateList = [];
          console.log(err);
        });
    },

    // 運搬契約登録状況別件数取得API    API_URL.COLLECTOR.REGIST_COUNT
    getCollectorRegistCountApi() {
      axios
        .get(API_URL.COLLECTOR.REGIST_COUNT)
        .then((res) => {
          this.collectorRegistCount = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 処理契約登録状況別件数取得API    API_URL.OPERATE.REGIST_COUNT
    getOperateRegistCountApi() {
      axios
        .get(API_URL.OPERATE.REGIST_COUNT)
        .then((res) => {
          this.operateRegistCount = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};