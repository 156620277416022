<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="処理事業者一覧" />
    <AppSideMenu />
    <main class="l-main">
      <ToastMessage :toastMsg.sync="toastMsg" />

      <!-- 検索フォーム ============================================================ -->
      <div>
        <div class="c-searchMenu__wrap">
          <div v-if="isDisplaySearch" class="c-searchMenu">
            <div class="l-flex">
              <div v-if="shorten().length === 0">
                <div class="title icon">条件なし</div>
              </div>
              <template v-else v-for="(s, index) in shorten()">
                <div class="title" :class="[{ icon: index === 0 }]" :key="'shorten-' + index">
                  {{ s.key }}<div class="data"> : {{ s.value }}</div>
                </div>
              </template>
            </div>
            <div class="l-flex">
              <div class="c-btn secondary sm" @click="scrollTop">検索条件を変更</div>
            </div>
          </div>
        </div>
        <div class="l-full-white l-area">
          <div class="l-box l-flex">
            <h3 class="c-lead-s-black icon">検索</h3>
            <div class="c-tabs l-area-l">
              <div class="c-tabs__each" :class="{ active: !isSearchDetail }"
                  @click="isSearchDetail = false;"
              >
                簡易
              </div>
              <div class="c-tabs__each" :class="{ active: isSearchDetail }"
                  @click="isSearchDetail = true;"
              >
                詳細
              </div>
            </div>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__items__cols search-wrap">
                <InputText
                    class="xlg"
                    :value.sync="searchParam.tempInputSearch"
                    inputType="text"
                    placeholder="企業名、廃棄物種類を入力（スペース区切りでOR検索）"
                    :validation="validateMaxLen"
                    :validateArg="100"
                >
                  <span class="text-label">キーワード</span>
                </InputText>
                <div>
                  <span class="text-label">事業形態</span>
                  <div class="l-flex cols">
                    <div v-for="(businessType, index) in businessTypeOpt"
                         :key="'business-type-' +index"
                         class="c-input"
                    >
                      <div class="c-checkbox">
                        <input :id="`business-type-${index}`"
                               type="checkbox"
                               v-model="searchParam.businessTypeIdList"
                               :value="businessType.id"
                        />
                        <label class="c-checkbox__label" :for="`business-type-${index}`">
                          <span class="c-checkbox__box"></span>
                          {{ businessType.name }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <span class="text-label">CBA加入</span>
                  <div class="l-flex cols">
                    <div v-for="flag in [true, false]"
                         :key="'is-cba-contact-' + flag"
                         class="c-input"
                    >
                      <div class="c-checkbox">
                        <input :id="'is-cba-contact-' + flag"
                               type="checkbox"
                               v-model="searchParam.isCBAContractList"
                               :value="flag"
                        />
                        <label class="c-checkbox__label" :for="'is-cba-contact-' + flag">
                          <span class="c-checkbox__box"></span>
                          {{ flag ? "有" : "無" }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 詳細のときだけ表示 -->
            <template v-if="isSearchDetail">
              <div class="c-inputWrap">
                <div class="c-inputWrap__items__cols search-wrap">
                  <div>
                    <span class="text-label">アカウント開放日&nbsp;※CBA加入者のみ</span>
                    <div class="c-inputWrap__items__cols search-wrap">
                      <InputDate
                          :value.sync="searchParam.accountStartDateFrom"
                          placeholder="0000/00/00"
                      />
                      <span>〜</span>
                      <InputDate
                          :value.sync="searchParam.accountStartDateTo"
                          placeholder="0000/00/00"
                      />
                    </div>
                  </div>
                  <div>
                    <span class="text-label"></span>
                    <div class="l-flex cols">
                      <div class="c-input">
                        <div class="c-checkbox">
                          <input
                              id="status"
                              type="checkbox"
                              v-model="searchParam.contractStatusTypeId"
                          />
                          <label class="c-checkbox__label" for="status">
                            <span class="c-checkbox__box"></span>
                            準備完了メール未設定({{ operateRegistCount.notAccountStartDateCount }})
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__items__cols search-wrap">
                  <div>
                    <span class="text-label">取り扱い廃棄物種類</span>
                    <div class="l-flex cols">
                      <span v-if="searchParam.jwnetItemTypeSearch.length">{{ searchParam.jwnetItemTypeSearch.map(j => j.name).join("、") }}</span>
                      <div @click="isDisplayWasteType = true" class="c-btn secondary mini">
                        対象廃棄物種類設定
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="l-container">
          <div class="l-btns l-block-lg">
            <div class="c-btn secondary small" @click="resetSearchHandler">
              条件をリセット
            </div>
            <div class="c-btn primary small" @click="searchHandler">検索</div>
          </div>
        </div>
      </div>

      <!-- 検索結果 ============================================================ -->
      <div class="l-container">
        <div class="c-infoList scroll l-box">
          <div class="c-infoList__row head">
            <div class="c-infoList__cell md">
              <a @click="clickSortChangeHandler('company_name')">
                    <span class="c-infoList__cell__sort" :class="{
                        asc: sortParams.type.toLowerCase() === 'asc' && sortParams.column === 'company_name',
                        desc: sortParams.type.toLowerCase() === 'desc' && sortParams.column === 'company_name',
                        'sort-disabled': sortParams.column !== 'company_name',
                      }"
                    >企業名</span>
              </a>
            </div>
            <div class="c-infoList__cell sm">
              <a @click="clickSortChangeHandler('business_code')">
                    <span class="c-infoList__cell__sort" :class="{
                        asc: sortParams.type.toLowerCase() === 'asc' && sortParams.column === 'business_code',
                        desc: sortParams.type.toLowerCase() === 'desc' && sortParams.column === 'business_code',
                        'sort-disabled': sortParams.column !== 'business_code',
                      }"
                    >事業者コード</span>
              </a>
            </div>
            <div class="c-infoList__cell sm">
              <a @click="clickSortChangeHandler('business_type')">
                    <span class="c-infoList__cell__sort" :class="{
                        asc: sortParams.type.toLowerCase() === 'asc' && sortParams.column === 'business_type',
                        desc: sortParams.type.toLowerCase() === 'desc' && sortParams.column === 'business_type',
                        'sort-disabled': sortParams.column !== 'business_type',
                      }"
                    >事業形態</span>
              </a>
            </div>
            <div class="c-infoList__cell sm">電話番号</div>
            <div class="c-infoList__cell md">取り扱い廃棄物種類</div>
            <div class="c-infoList__cell sm">アカウント開放日</div>
            <div class="c-infoList__cell xxsm"></div>
          </div>

          <!-- 検索結果あり -->
          <template v-if="operateList.length">
            <div
                v-for="(operate, operateIndex) of operateList"
                :key="'operate-' + operateIndex"
                class="c-infoList__row list"
            >
              <div class="c-infoList__cell md c-tooltip">
                <router-link :to="gotoDetail(operate)">
                  {{ operate.operateCompanyInfo.name.length > 30 ? (operate.operateCompanyInfo.name.slice(0, 30) + '...') : operate.operateCompanyInfo.name }}
                </router-link>
                <div v-if="operate.operateCompanyInfo.name.length > 30" class="c-tooltip__balloon lh">
                  <span>{{ operate.operateCompanyInfo.name }}</span>
                </div>
                <div v-if="operate.isCBAContract" class="c-badge-black">
                  CBA加入
                </div>
              </div>
              <div class="c-infoList__cell sm">
                {{ operate.businessCode | blankText }}
              </div>
              <div class="c-infoList__cell sm">
                {{ businessTypeOpt.map(b => b.id).includes(operate.businessTypeId) ? businessTypeOpt.filter(b => b.id === operate.businessTypeId)[0].name : "-" }}
              </div>
              <div class="c-infoList__cell sm">
                {{ operate.tel }}
              </div>
              <div class="c-infoList__cell md">
                <CommaText :items="operate.jwnetItemList" arg="name" />
              </div>
              <div class="c-infoList__cell sm">
                {{ operate.accountStartDate | dateJpMDddFormat }}
              </div>
              <div class="c-infoList__cell xxsm sticky">
                <div>
                  <router-link :to="gotoEdit(operate)">
                    <div
                        class="c-btn-icon edit secondary small"
                    ></div>
                  </router-link>
                </div>
              </div>
            </div>
          </template>

          <!-- 検索結果なし -->
          <template v-else>
            <div class="c-infoList__row list">
              <div class="c-infoList__cell">
                検索条件に一致する運搬事業者・処分事業者がありません。
              </div>
            </div>
          </template>
        </div>

        <Pagination v-if="operateList.length"
            :params="paginationParams"
            :page.sync="paginationParams.page"
            @callback="getOperateSearchApi(true)"
        />
      </div>
    </main>
    <!-- 対象廃棄物種類設定のモーダル -->
    <div v-if="isDisplayWasteType" class="c-modal lg">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__head">
          <h5 class="c-lead-s">対象廃棄物種類設定</h5>
          <span
            class="c-modal__head__close"
            @click="isDisplayWasteType = false"
          ></span>
        </div>
        <div class="c-modal__body">
          <div class="c-modal__body__originalList">
            <div class="c-radio">
              <input
                id="industrial-waste"
                type="radio"
                name="industrial-waste"
                :value="true"
                v-model="isIndustrialWaste"
                @change="searchParam.jwnetItemTypeSearch = []"
              />
              <label class="c-radio__label" for="industrial-waste">
                <span class="c-radio__box"></span>
                産業廃棄物
              </label>
            </div>
            <div class="c-radio">
              <input
                id="special-waste"
                type="radio"
                name="special-waste"
                :value="false"
                v-model="isIndustrialWaste"
                @change="searchParam.jwnetItemTypeSearch = []"
              />
              <label class="c-radio__label" for="special-waste">
                <span class="c-radio__box"></span>
                特別管理産業廃棄物
              </label>
            </div>
          </div>
        </div>
        <div class="c-modal__body">
          <div>
            <div v-if="isIndustrialWaste" class="c-modal__body__originalList">
              <div
                class="c-checkbox"
                v-for="jwnetNormal in jwnetItemsOpt.filter(j => !j.isSpecial)"
                :key="'normal-waste-' + jwnetNormal.id"
              >
                <input
                  :id="'normal-waste-' + jwnetNormal.id"
                  type="checkbox"
                  name="waste-type"
                  :value="jwnetNormal"
                  v-model="searchParam.jwnetItemTypeSearch"
                />
                <label class="c-checkbox__label" :for="'normal-waste-' + jwnetNormal.id">
                  <span class="c-checkbox__box"></span>
                  {{ jwnetNormal.name }}
                </label>
              </div>
            </div>
            <div v-else class="c-modal__body__originalList">
              <div
                class="c-checkbox"
                v-for="jwnetSpecial in jwnetItemsOpt.filter(j => j.isSpecial)"
                :key="'special-waste-' + jwnetSpecial.id"
              >
                <input
                  :id="'special-waste-' + jwnetSpecial.id"
                  type="checkbox"
                  name="waste-type"
                  :value="jwnetSpecial"
                  v-model="searchParam.jwnetItemTypeSearch"
                />
                <label class="c-checkbox__label" :for="'special-waste-' + jwnetSpecial.id">
                  <span class="c-checkbox__box"></span>
                  {{ jwnetSpecial.name }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="c-modal__foot">
          <div class="c-btn primary small" @click="isDisplayWasteType = false">
            設定
          </div>
        </div>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import moment from "moment";
import InputText from "@/components/parts/input/InputText";
import InputDate from "@/components/parts/input/InputDate";
import Pagination from "@/components/parts/Pagination.vue";
import CommaText from "@/components/parts/CommaText";
import callApi from "./mixins/callApi";
import search from "./mixins/search";
import common from "@/mixin/common";
import validation from "@/mixin/validation";
import {DOMAIN} from "@/const";
import ToastMessage from "@/components/parts/ToastMessage";

export default {
  name: "operate",
  components: {
    ToastMessage,
    InputText,
    InputDate,
    Pagination,
    CommaText,
  },
  mixins: [validation, callApi, common, search],
  data() {
    return {
      operateList: [],
      isDisplaySearch: false,
      isSearchDetail: this.$store.getters["searchParamOperate/isSearchDetail"],
      isDisplayWasteType: false,
      isIndustrialWaste: true,
      // ページネーション用
      paginationParams: {
        page: 1,
        pageSize: 20,
        total: null,
      },
      sortParams: {
        column: "company_name",
        type: "desc",
      },
      // 検索v-model
      searchParam: {
        tempInputSearch: "", // キーワード一時入力
        businessTypeIdList: [],
        isCBAContractList: [],
        accountStartDateFrom: "", // アカウント開放日From
        accountStartDateTo: "", // アカウント開放日To
        contractStatusTypeId: false,
        jwnetItemTypeSearch: [],
      },
      today: moment(),
      businessTypeOpt: [
        { id: DOMAIN.BUSINESS_TYPE.COLLECTOR, name: "運搬" },
        { id: DOMAIN.BUSINESS_TYPE.DISPOSER, name: "処分" },
      ],
      collectorRegistCount: {},
      operateRegistCount: {},
      toastMsg: "",
    };
  },
  computed: {
  },
  mounted() {
    this.searchParam = this.$store.getters["searchParamOperate/get"];

    window.addEventListener("scroll", this.handleScroll);

    this.getCollectorRegistCountApi();
    this.getOperateRegistCountApi();
    this.getJwnetItemTypeApi();
    this.getOperateSearchApi();
  },
  watch: {
    isSearchDetail() {
      this.initSearch(false);
    },
  },

  beforeCreate() {
    // クエリパラメータのチェック
    if (this.$route.query.mailNotSetting) {
      this.$store.commit("searchParamOperate/set", {
        tempInputSearch: "",
        businessTypeIdList: [DOMAIN.BUSINESS_TYPE.COLLECTOR],
        isCBAContractList: [],
        accountStartDateFrom: "",
        accountStartDateTo: "",
        contractStatusTypeId: true,
        jwnetItemTypeSearch: [],
      });
    } else if(this.$route.query.accountStartDateFrom) {
      this.$store.commit("searchParamOperate/set", {
        tempInputSearch: "",
        businessTypeIdList: [DOMAIN.BUSINESS_TYPE.COLLECTOR],
        isCBAContractList: [],
        accountStartDateFrom: this.$route.query.accountStartDateFrom,
        accountStartDateTo: "",
        contractStatusTypeId: false,
        jwnetItemTypeSearch: [],
      });
    }
  },

  methods: {
    initSearch(isAll = true) {
      this.searchParam = {
        tempInputSearch:  isAll ? "" : this.searchParam.tempInputSearch, // キーワード一時入力
        businessTypeIdList: isAll ? [] : this.searchParam.businessTypeIdList,
        isCBAContractList: isAll ? [] : this.searchParam.isCBAContractList,
        accountStartDateFrom: "", // アカウント開放日From
        accountStartDateTo: "", // アカウント開放日To
        contractStatusTypeId: false,
        jwnetItemTypeSearch: [],
      };
    },
    resetSearchHandler() {
      this.initSearch();
    },
    searchHandler() {
      this.getOperateSearchApi();
    },
    // ソート変更（企業名、事業形態）
    clickSortChangeHandler(column) {
      if (this.sortParams.column !== column) {
        this.sortParams.type = "desc";
      } else {
        this.sortParams.type = this.sortParams.type.toLowerCase() === "asc" ? "desc" : "asc";
      }
      this.sortParams.column = column;
      this.getOperateSearchApi(false);
    },
    gotoDetail(operate) {
      switch (operate.businessTypeId) {
        case DOMAIN.BUSINESS_TYPE.COLLECTOR:
          return "/collector/" + operate.operateCompanyInfo.id;
        case DOMAIN.BUSINESS_TYPE.DISPOSER:
          return "/disposer/" + operate.operateCompanyInfo.id;
      }
    },
    gotoEdit(operate) {
      switch (operate.businessTypeId) {
        case DOMAIN.BUSINESS_TYPE.COLLECTOR:
          return "/collector/step1/edit/" + operate.operateCompanyInfo.id;
        case DOMAIN.BUSINESS_TYPE.DISPOSER:
          return "/disposer/step1/edit/" + operate.operateCompanyInfo.id;
      }
    },

    handleScroll() {
      this.scrollY = window.scrollY;
      if (!this.isDisplaySearch) {
        this.isDisplaySearch = window.scrollY > 200;
      } else if (window.scrollY < 190) {
        this.isDisplaySearch = !this.isDisplaySearch;
      }
    },

    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    shorten() {
      const result = [];
      const prev = this.$store.getters["searchParamOperate/get"];

      if (prev.tempInputSearch) result.push({ key: "キーワード", value: prev.tempInputSearch });
      if (prev.businessTypeIdList.length) result.push({ key: "事業形態", value: prev.businessTypeIdList.map(b => this.businessTypeOpt.filter(opt => opt.id === b)[0].name).join("、") });
      if (prev.isCBAContractList.length === 1) result.push({ key: "CBA加入", value: prev.isCBAContractList[0] ? "有" : "無" });
      if (prev.accountStartDateFrom || prev.accountStartDateTo) result.push({ key: "アカウント開放日", value: prev.accountStartDateFrom + "〜" + prev.accountStartDateTo });
      if (prev.contractStatusTypeId) result.push({ key: "準備完了メール未設定" });
      if (prev.jwnetItemTypeSearch.length) result.push({ key: "取り扱い廃棄物種類", value: prev.jwnetItemTypeSearch.map(j => this.jwnetItemsOpt.filter(opt => opt.id === j.id)[0].name).join("、") });

      return result;
    },
  },
};
</script>
