<template>
<div class="comma_text">
  {{ joinCommaDotted(items, arg) }}
  <div
      v-if="countRemainItems(items, arg) !== false"
      class="c-infoList__badge"
  >
    他
    <div class="c-infoList__popup" :class="popupSize">
        <span>{{joinComma(items, arg)}}</span>
    </div>
  </div>
</div>
</template>

<script>
import common from "@/mixin/common";

export default {
  props: {
    maxLength: {
      type: Number, 
      default: 17,
    } ,
    items: {
      type: Array,
      default: null,
    },
    /** nullの場合、配列の要素自体を表示する */
    arg: {
      type: String,
      default: null,
    },
    /** 項目が無いとき - 表示するかどうか */
    showblankText: {
      type: Boolean,
      default: false,
    },
    popupSize: {
      type: String,
      default: null,
    },
  },
  mixins: [common],
  computed: {
    /**
     * joinCommaDottedで表示しきれない項目の数を返す
     * @return false|number false:他表示しない
     */
    countRemainItems: function () {
      return function (objArr, arg) {
        if (!objArr || !objArr.length) return false;

        const notEmptyArr = !arg ? objArr : objArr.filter(obj => this.isNotEmpty(obj[arg]));
        const allItemsCount = notEmptyArr.length;
        if (!allItemsCount) return false

        const joinCommaText = this.joinComma(objArr, arg);
        const visibleText = this.joinCommaDotted(objArr, arg, joinCommaText);

        const notHasDot = (visibleText.indexOf("…") < 0);
        if (notHasDot) return false

        const visibleCommasArr = visibleText.match(/、/g);
        const visibleCommasCount = visibleCommasArr == null ? 0 : visibleCommasArr.length;

        const IsCommaNextMaxLength = joinCommaText.charAt(this.maxLength) === '、';

        // NOTE: [...]の直後が[、]の場合、+1
        const visibleItemsCount = IsCommaNextMaxLength ? visibleCommasCount + 1 : visibleCommasCount;

        const remainItemsCount = allItemsCount - visibleItemsCount;
        
        // NOTE: [...]で見切れた項目も他に含める
        return remainItemsCount;
      }
    },
    // { arg: "hoge" }の配列からhogeのみ取り出して「、」繋ぎで返す
    joinCommaDotted: function () {
      return function (objArr, arg, joinComma = null) {
        if (!objArr || !objArr.length) return this.showblankText ? '-' : '';

        const targetText = joinComma ?? this.joinComma(objArr, arg);
        return this.dottedText(targetText, this.maxLength);
      }
    },
  },
  methods: {
    // 配列をカンマでつなげる
    joinComma(objArr, arg) {
      if (!objArr) return '';
      if (!arg) return objArr.join("、");

      const tmpArray = [];
      for (const obj of objArr) {
        const item = obj[arg];
        if (this.isNotEmpty(item)) {
          tmpArray.push(item);
        }
      }
      return tmpArray.join("、");
    },
    isNotEmpty(item) {
      return item != null && item !== '';
    },

  }
};
</script>

<style>
.comma_text {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
</style>